
import AmazonAlexaDownloadInteractionModelButton from '@/components/amazon-alexa/AmazonAlexaDownloadInteractionModelButton.vue';
import AmazonAlexaSettingsCard from '@/components/amazon-alexa/AmazonAlexaSettingsCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-platform-amazon-alexa',
  components: { AmazonAlexaDownloadInteractionModelButton, AmazonAlexaSettingsCard },
})
export default class ProjectPlatformAmazonAlexa extends mixins(ProjectViewMixin) {}
