
import { BotMixin } from '@/mixins/BotMixin';
import { startDownload } from '@/utilities';
import { BotLanguageModelBuilderAdapter, SupportedPlatform } from 'ignite360-core';
import { JovoModelAlexa } from '@jovotech/model-alexa';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'amazon-alexa-download-interaction-model-button',
})
export default class AmazonAlexaDownloadInteractionModelButton extends mixins(BotMixin) {
  downloadModel() {
    if (!this.bot) {
      return;
    }

    const modelData = new BotLanguageModelBuilderAdapter(
      this.bot,
      SupportedPlatform.AmazonAlexa,
    ).build();
    const jovoModelInstance = new JovoModelAlexa();
    try {
      jovoModelInstance.importJovoModel(modelData, 'en-US');
      const nativeInformation = jovoModelInstance.exportNative() || [];
      const interactionModel =
        nativeInformation.length > 0 ? nativeInformation[0].content : undefined;

      if (!interactionModel) {
        console.log('no interaction model');
        
      }

      const blob = new Blob([JSON.stringify(interactionModel)], {
        type: 'application/json',
      });
      startDownload(blob, 'alexa-interaction-model.json');
    } catch (e) {
      // TODO: implement error handling
      console.log(e);
      
    }
  }
}
