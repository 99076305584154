
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { AmazonAlexaPlatformData, SupportedPlatform } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

type AmazonAlexaSettings = Pick<AmazonAlexaPlatformData, 'enabled' | 'skillId' | 'invocation'>;

@Component({
  name: 'amazon-alexa-settings-card',
  components: {},
})
export default class AmazonAlexaSettingsCard extends mixins(BotMixin) {
  amazonAlexaSettings = this.resetValue();

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.amazonAlexaSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBotPlatform({
        id: this.bot.id,
        platform: SupportedPlatform.AmazonAlexa,
        data: this.amazonAlexaSettings,
      });
      this.$notify.success('Successfully updated Amazon Alexa-settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating Amazon Alexa-settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.amazonAlexaSettings = this.resetValue();
  }

  private resetValue(): AmazonAlexaSettings {
    return {
      enabled: this.bot.platformData.amazonAlexa.enabled,
      skillId: this.bot.platformData.amazonAlexa.skillId,
      invocation: this.bot.platformData.amazonAlexa.invocation,
    };
  }
}
